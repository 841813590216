@import 'styles/utils/mixins';

.overflowApp {
  height: 100%;
  overflow: hidden;
}

.hideScroll {
  will-change: transform;
  transform: translateY(200px);
}

.headerContainer {
  @include flex(row, space-between, center);

  position: fixed;
  top: 0;
  left: 0;
  z-index: 80;
  padding: 16px 32px;
  max-height: 88px;
  max-width: 100vw;
  height: 100%;
  width: 100%;
  opacity: 1;
  transition:
    padding $default-transition-time,
    background-color 0.5s;
  box-sizing: border-box;

  &.notHome {
    background-color: $header-additional-color;
    border-bottom: 0.5px solid $gray200;

    div:last-child {
      hr {
        background-color: $gray1000;
      }
    }
  }

  &.pageScrolling {
    will-change: transform;
    background-color: $header-additional-color;
    transition:
      transform $default-transition-time,
      background-color $default-transition-time,
      padding $default-transition-time * 1.5;

    @include phone {
      width: 100%;
    }
  }

  &.openedDropDown:not(.pageScrolling, .notHome) {
    background-color: $gray900;
    transition: background-color 0.3s ease;
  }

  .logo {
    cursor: pointer;
    text-align: center;
    width: $logo-size;
    height: 45px;
    overflow: hidden;
    transition: width $default-transition-time ease;

    & > a {
      img {
        width: 44px;
        height: 44px;
      }

      svg {
        transition: width $default-transition-time;
      }
    }

    @include below-tablet {
      z-index: 10000000;
    }
  }

  ul {
    margin: 0;
  }

  &.down {
    will-change: transform;
    transform: translate3d(0, -100%, 0);
    transition: transform 0.7s;
  }

  &.up {
    will-change: transform;
    transform: translate3d(0, 0, 0);
  }

  &.grayHeader {
    background-color: $gray50;
    border-bottom: 0;
  }

  @include tablet {
    padding: 16px 23px;
  }

  @include phone {
    padding: 10px 16px;
    height: unset;
  }
}

.animate {
  will-change: transform;
  transform: translate3d(0, 0, 0);
}

.deleteTextOfLogo {
  .logo {
    overflow: hidden;
    width: 50px;
  }

  &.pageScrolling {
    .logo {
      width: 47px;
    }
  }
}
